<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangeMixin from "@/mixins/DateRangeMixin";

import ProjectService from "@/services/ProjectService";

export default {
    data() {
        return {
            loading: false,
            stats: null,
            dateRange: {
                startDate: null,
                endDate: null,
            },
        };
    },
    mixins: [DateRangeMixin],
    components: { DateRangePicker },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        currentExportUrl: function () {
            let url = new URL(
                location.protocol +
                    "//" +
                    location.host +
                    "/v1/projects/global-statistics"
            );
            url.searchParams.append("csv", 1);
            url.searchParams.append(
                "dateRange",
                JSON.stringify(this.dateRange)
            );

            return url.href;
        },
    },
    watch: {
        dateRange: function () {
            this.fetchData();
        },
    },
    async created() {
        this.$aclGuard(this.$ACL.STATS_VIEW);
        await this.fetchData();
    },
    methods: {
        resetSearch() {
            this.dateRange = {
                startDate: null,
                endDate: null,
            };
        },
        async fetchData() {
            try {
                this.loading = true;
                const r = await ProjectService.GET_globalStatistics({
                    dateRange: this.serializeDateRange(this.dateRange),
                });
                this.stats = r.data.data;
            } catch (e) {
                console.log("fetchData e", e);
            }
            this.loading = false;
        },
    },
};
</script>

<template>
    <div class="v-reports-projects">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-3">
                    <date-range-picker
                        class="range-picker"
                        ref="picker"
                        opens="right"
                        :timePicker="false"
                        :showWeekNumbers="true"
                        :autoApply="true"
                        v-model="dateRange"
                        :linkedCalendars="true"
                        :locale-data="getDateRangePickerLocale()"
                        :ranges="getDateRangePickerRanges()"
                    >
                        <template #header>
                            <manual-range
                                :date-range-manual="dateRangeManual"
                                :date-range-error="dateRangeError"
                                @exec="execManualRange('dateRange')"
                            />
                        </template>
                        <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                        >
                            <template v-if="picker.startDate">
                                {{ $d(picker.startDate, "short") }}
                                -
                                {{ $d(picker.endDate, "short") }}
                            </template>
                        </template>
                    </date-range-picker>
                </div>
                <div class="col-9">
                    <base-button
                        type="white"
                        @click="resetSearch"
                        :outline="false"
                        ><i class="far fa-redo"></i>
                        {{ $t("search.reset") }}</base-button
                    >
                    <a
                        :href="currentExportUrl"
                        class="btn btn-primary"
                        target="_blank"
                        >{{ $t("projects.download_as_csv") }}</a
                    >
                </div>
            </div>

            <div class="row card-wrapper mt-3">
                <div class="col-12">
                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <div
                            class="table-responsive"
                            v-else-if="
                                'table' in stats && stats.table.length > 0
                            "
                        >
                            <v-table
                                class="table table-mg table-sm table-striped"
                            >
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t(
                                                "project_g_stats.case_and_customer"
                                            )
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t("project_g_stats.all_tasks")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "project_g_stats.all_billable_tasks"
                                            )
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "project_g_stats.all_non_billable_tasks"
                                            )
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "project_g_stats.to_invoice_tasks"
                                            )
                                        }}</v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr
                                        v-for="(s, index) in stats.table"
                                        :key="index"
                                    >
                                        <v-td v-if="false">
                                            <pre>{{ s }}</pre>
                                        </v-td>
                                        <v-td
                                            >{{ s.project_title }}
                                            <span class="text-muted"
                                                >({{ s.project_id }})</span
                                            >
                                            <div class="text-muted small">
                                                {{ s.customer_name }}
                                            </div>
                                        </v-td>
                                        <v-td class="text-right"
                                            >{{
                                                $n(s.total_task_price, {
                                                    style: "currency",
                                                    currency: s.currency,
                                                    currencyDisplay: "code",
                                                })
                                            }}
                                            <div class="text-muted">
                                                <i class="far fa-clock"></i>
                                                {{ s.total_task_time | toTime }}
                                            </div>
                                        </v-td>
                                        <v-td class="text-right"
                                            >{{
                                                $n(
                                                    s.total_task_price_billable,
                                                    {
                                                        style: "currency",
                                                        currency: s.currency,
                                                        currencyDisplay: "code",
                                                    }
                                                )
                                            }}
                                            <div class="text-muted">
                                                <i class="far fa-clock"></i>
                                                {{
                                                    s.total_task_time_billable
                                                        | toTime
                                                }}
                                            </div>
                                        </v-td>
                                        <v-td class="text-right"
                                            >{{
                                                $n(
                                                    s.total_task_price_non_billable,
                                                    {
                                                        style: "currency",
                                                        currency: s.currency,
                                                        currencyDisplay: "code",
                                                    }
                                                )
                                            }}
                                            <div class="text-muted">
                                                <i class="far fa-clock"></i>
                                                {{
                                                    s.total_task_time_non_billable
                                                        | toTime
                                                }}
                                            </div></v-td
                                        >
                                        <v-td class="text-right"
                                            >{{
                                                $n(
                                                    s.total_task_price_to_invoice,
                                                    {
                                                        style: "currency",
                                                        currency: s.currency,
                                                        currencyDisplay: "code",
                                                    }
                                                )
                                            }}
                                            <div class="text-muted">
                                                <i class="far fa-clock"></i>
                                                {{
                                                    s.total_task_time_to_invoice
                                                        | toTime
                                                }}
                                            </div></v-td
                                        >
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                        </div>

                        <div class="px-4" v-else>
                            <div class="alert alert-warning">No data found</div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
</style>
